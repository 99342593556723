import React, { useState } from "react";
import { useContext, CSSProperties } from "react";
import valley from "../assets/valley.jpeg";
import { useIsMobile } from "../functions/isMobile";
import { Context } from "../functions/context";
import './home.css'
import {
  MultiChatWindow,
  MultiChatSocket,
  useMultiChatLogic,
  MessageFormProps,
  ChatCardProps,
  ChatHeaderProps,
} from "react-chat-engine-advanced";
import { 
  LogoutOutlined,
  HomeFilled,
  MessageFilled,
  SettingFilled,
} from "@ant-design/icons";
import { Avatar } from "react-chat-engine-advanced";
import "../theme.css";
import Sidebar from "./Sidebar";
import MessageForm from "./MessageForm";
import UserSearch from "./UserSearch";
import ChatCard from "./ChatCard";
import ChatHeader from "./ChatHeader";
import { projectId } from "../functions/constants";

const ChatsPage = () => {
  const { user, setUser } = useContext(Context);
  const isMobile: boolean = useIsMobile();
  const [hideContent, setHideContent] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const username: string = user ? user.username : "";
  const secret: string = user && user.secret !== null ? user.secret : "";
  const chatProps = useMultiChatLogic(projectId, username, secret);

  const backgroundImage = {
    backgroundImage: `url(${valley})`,
  } as CSSProperties;

  const toggleContentVisibility = () => {
    setIsActive(false);
    setHideContent(true);
  };
  const unToggleContentVisibility = () => {
    setIsActive(true);
    setHideContent(false);
  };
  const customStyles = {
    color: 'rgb(24, 144, 255) !important',
    borderLeft: '2px solid rgb(24, 144, 255)',
  };


  return (
    <div className="background-image" style={backgroundImage} >
      <div className="background-gradient-light">
        <div
          style={{
            position: "absolute",
            top: isMobile ? "0px" : "10vh",
            left: isMobile ? "0px" : "20px",
            height: isMobile ? "100vh" : "80vh",
            width: isMobile ? "100vw" : "calc(100vw - 2.6vw)",
            backgroundColor: "grey",
            borderRadius: "30px"
          }}
        >
          <div className="SideBar"
            style={{
              width: "6vw",
              height: "100%",
              position: "absolute",
              top: "0px",
              left: "0px",
              backgroundColor: "rgb(40,43,54)",
              borderRadius: "30px 0px 0px 30px",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <div className="ce-sidebar-menu" >
              <div onClick={toggleContentVisibility} >
                 <HomeFilled className="ce-sidebar-icon"  style={isActive ? {}: customStyles} />
                </div>
                <div onClick={unToggleContentVisibility} >
                  <MessageFilled className="ce-sidebar-icon" style={isActive ? customStyles : {}}/>
                </div>
 
              </div>

              <Avatar
                className="sidebar-avatar"
                avatarUrl={typeof user?.avatar === "string" ? user.avatar : undefined}
                username={user?.username}
                isOnline={true}
              />

              <LogoutOutlined
                onClick={() => setUser(undefined)}
                className="signout-icon"
              />
            </div>
          </div>





          <div className="homePage"
            style={{
              width: isMobile ? "100vw" : "calc(100vw - 8.75vw)",
              position: "absolute",
              top: "0px",
              left: isMobile ? "0px" : "6vw",
              height: "100%",
              display: hideContent ? "block" : "none",
              borderRadius: "00px 30px 30px 0px"
            }}>
              <h1 className="h-title">
                Blog
              </h1>
              <div className="grid">

  <div className="card firsts">
    <span className="icon">
      <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 9.5V5.5C4.5 4.94772 4.94772 4.5 5.5 4.5H9.5C10.0523 4.5 10.5 4.94772 10.5 5.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H5.5C4.94772 10.5 4.5 10.0523 4.5 9.5Z"
        />
        <path
          d="M13.5 18.5V14.5C13.5 13.9477 13.9477 13.5 14.5 13.5H18.5C19.0523 13.5 19.5 13.9477 19.5 14.5V18.5C19.5 19.0523 19.0523 19.5 18.5 19.5H14.5C13.9477 19.5 13.5 19.0523 13.5 18.5Z"
        />
        <path d="M4.5 19.5L7.5 13.5L10.5 19.5H4.5Z" />
        <path
          d="M16.5 4.5C18.1569 4.5 19.5 5.84315 19.5 7.5C19.5 9.15685 18.1569 10.5 16.5 10.5C14.8431 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 14.8431 4.5 16.5 4.5Z"
        />
      </svg>
    </span>
    <h4>Upload</h4>
    <p>
    I uploaded the website to GitHub with a simple chat app that you can sign up or log in to. In the app, you can talk to people via their usernames. You can also send files and delete chats. Next, I'm planning to add group chat functionality
    </p>
    <div className="shine"></div>
    <div className="background">
      <div className="tiles">
        <div className="tile tile-1">whats</div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
        <div className="tile tile-4"></div>

        <div className="tile tile-5"></div>
        <div className="tile tile-6"></div>
        <div className="tile tile-7"></div>
        <div className="tile tile-8"></div>

        <div className="tile tile-9"></div>
        <div className="tile tile-10"></div>
      </div>

      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
    </div>
  </div>
  <div className="card">
    <span className="icon">

    <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 9.5V5.5C4.5 4.94772 4.94772 4.5 5.5 4.5H9.5C10.0523 4.5 10.5 4.94772 10.5 5.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H5.5C4.94772 10.5 4.5 10.0523 4.5 9.5Z"
        />
        <path
          d="M13.5 18.5V14.5C13.5 13.9477 13.9477 13.5 14.5 13.5H18.5C19.0523 13.5 19.5 13.9477 19.5 14.5V18.5C19.5 19.0523 19.0523 19.5 18.5 19.5H14.5C13.9477 19.5 13.5 19.0523 13.5 18.5Z"
        />
        <path d="M4.5 19.5L7.5 13.5L10.5 19.5H4.5Z" />
        <path
          d="M16.5 4.5C18.1569 4.5 19.5 5.84315 19.5 7.5C19.5 9.15685 18.1569 10.5 16.5 10.5C14.8431 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 14.8431 4.5 16.5 4.5Z"
        />
      </svg>
    </span>
    <h4>Login - group chats - blog</h4>
    <p>
    n this update, I added a column at the right side of the chat page which you can use to: create group chats, view all the images, and manage the group. I also added another page for the blogs, which is this page, and finally, I removed the ability to create accounts temporarily.
    </p>
    <div className="shine"></div>
    <div className="background">
      <div className="tiles">
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
        <div className="tile tile-4"></div>

        <div className="tile tile-5"></div>
        <div className="tile tile-6"></div>
        <div className="tile tile-7"></div>
        <div className="tile tile-8"></div>

        <div className="tile tile-9"></div>
        <div className="tile tile-10"></div>
      </div>

      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
    </div>
  </div>
  <div className="card">
    <span className="icon">

    <svg
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.5 9.5V5.5C4.5 4.94772 4.94772 4.5 5.5 4.5H9.5C10.0523 4.5 10.5 4.94772 10.5 5.5V9.5C10.5 10.0523 10.0523 10.5 9.5 10.5H5.5C4.94772 10.5 4.5 10.0523 4.5 9.5Z"
        />
        <path
          d="M13.5 18.5V14.5C13.5 13.9477 13.9477 13.5 14.5 13.5H18.5C19.0523 13.5 19.5 13.9477 19.5 14.5V18.5C19.5 19.0523 19.0523 19.5 18.5 19.5H14.5C13.9477 19.5 13.5 19.0523 13.5 18.5Z"
        />
        <path d="M4.5 19.5L7.5 13.5L10.5 19.5H4.5Z" />
        <path
          d="M16.5 4.5C18.1569 4.5 19.5 5.84315 19.5 7.5C19.5 9.15685 18.1569 10.5 16.5 10.5C14.8431 10.5 13.5 9.15685 13.5 7.5C13.5 5.84315 14.8431 4.5 16.5 4.5Z"
        />
      </svg>
    </span>
    <h4>N A</h4>
    <p>
      NA
    </p>
    <div className="shine"></div>
    <div className="background">
      <div className="tiles">
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
        <div className="tile tile-4"></div>

        <div className="tile tile-5"></div>
        <div className="tile tile-6"></div>
        <div className="tile tile-7"></div>
        <div className="tile tile-8"></div>

        <div className="tile tile-9"></div>
        <div className="tile tile-10"></div>
      </div>

      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
    </div>
  </div>
</div>
</div>





          <div className="Chat page"
            style={{
              width: isMobile ? "100vw" : "calc(100vw - 6vw)",
              position: "absolute",
              top: "0px",
              left: isMobile ? "0px" : "6vw",
              height: "100%",
              display: hideContent ? "none" : "block",
              borderRadius: "30px",
            }}
          >
            <MultiChatSocket {...chatProps} />

            <MultiChatWindow
              {...chatProps}
              renderChatForm={() => (
                <UserSearch
                  username={chatProps.username}
                  secret={chatProps.secret}
                  onSelect={(chatId: number) =>
                    chatProps.onChatCardClick(chatId)
                  }
                />
              )}
              renderChatCard={(props: ChatCardProps) => (
                <ChatCard
                  {...props}
                  username={chatProps.username}
                  onChatCardClick={chatProps.onChatCardClick}
                  isActive={
                    props.chat !== undefined &&
                    chatProps.activeChatId === props.chat.id
                  }
                  chat={props.chat}
                />
              )}
              renderChatHeader={(props: ChatHeaderProps) => (
                <ChatHeader
                  {...props}
                  chat={chatProps.chat}
                  username={chatProps.username}
                  secret={chatProps.secret}
                />
              )}
              renderMessageForm={(props: MessageFormProps) => (
                <MessageForm {...props} />
              )}
              // renderChatSettings={() => <div className="ce-empty-settings" />}
              // style={{ height: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatsPage;
